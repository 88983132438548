import { useState, useEffect } from 'react'

const baseUrl = process.env.REACT_APP_API_URL

console.debug(baseUrl)

const useJsonApi = ({
  url = '',
  method = 'GET',
  payload,
  headers = {
    'Content-Type': 'application/json',
  },
  autostart = false,
  ...fetchOptions
} = {}, finalizers = []) => {
  const [ startTime, setStartTime ] = useState(autostart ? Date.now() : 0)
  const [ requestUrl, setRequestUrl ] = useState(baseUrl + url)
  const [ requestPayload, setRequestPayload ] = useState(payload)
  const [ requestHeaders, setRequestHeaders ] = useState(headers)
  const [ requestFinalizers, setRequestFinalizers ] = useState(finalizers)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isError, setIsError ] = useState(false)

  useEffect(() => {
    const run = async () => {
      if (isLoading) {
        return
      }
      setIsError(false)
      setIsLoading(true)
      try {
        const response = await fetch(requestUrl, {
          method,
          body: method !== 'GET' ? requestPayload : undefined,
          headers: requestHeaders,
          ...fetchOptions,
        })
        const result = await response.json()
        for (const finalize of requestFinalizers) {
          if (typeof finalize === 'function') {
            finalize(result)
          }
        }
      } catch (error) {
        setIsError(true)
      }
      setIsLoading(false)
    }
    if (autostart || startTime) {
      run()
    }
  }, [ startTime ]) // eslint-disable-line

  return {
    request: () => setStartTime(Date.now()),
    startTime,
    setRequestUrl: (u) => setRequestUrl(baseUrl + u),
    setRequestPayload,
    setRequestHeaders,
    setRequestFinalizers,
    isLoading,
    isError,
  }
}

const useReadGuests = () => {
  const [ guests, setGuests ] = useState([])
  const { start: fetchGuests } = useJsonApi({
    url: `/api/guests/`,
    autostart: true,
    credentials: 'include',
    mode: 'cors',
  }, [ setGuests ])

  return { guests, setGuests, fetchGuests }
}

const useCreateGuest = (finalizers = []) => {
  const { request, setRequestPayload, setRequestFinalizers } = useJsonApi({
    url: `/api/guests`,
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
  }, finalizers)

  const createGuest = (data, callbacks) => {
    setRequestPayload(JSON.stringify(data))
    setRequestFinalizers(finalizers.concat(callbacks))
    request()
  }

  return { createGuest }
}

const useReadGuest = (id, finalizers = []) => {
  const [ guest, setGuest ] = useState({})
  const { request: fetchGuest } = useJsonApi({
    url: `/api/guests/${id}`,
    autostart: true,
  }, [ setGuest ].concat(finalizers))

  return { guest, setGuest, fetchGuest }
}

const useUpdateGuest = (finalizers = []) => {
  const { request, setRequestPayload, setRequestUrl, setRequestFinalizers } = useJsonApi({
    method: 'PUT',
    credentials: 'include',
    mode: 'cors',
  }, finalizers)

  const updateGuest = (id, data, callbacks) => {
    setRequestUrl(`/api/guests/${id}`)
    setRequestPayload(JSON.stringify(data))
    setRequestFinalizers(finalizers.concat(callbacks))
    request()
  }

  return { updateGuest }
}

const useSendGuestEmail = (finalizers = []) => {
  const { request, setRequestPayload, setRequestUrl, setRequestFinalizers } = useJsonApi({
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
  }, finalizers)

  const sendGuestEmail = (id, emailType, callbacks) => {
    return new Promise((resolve, reject) => {
      setRequestUrl(`/api/guests/${id}/emails/${emailType}`)
      setRequestPayload(JSON.stringify({}))
      setRequestFinalizers(finalizers.concat(callbacks).concat((d) => resolve(d)))
      request()
    })
  }

  return { sendGuestEmail }
}

const useVerifyGuest = (id, setGuest) => {
  const { request, isLoading, setRequestPayload } = useJsonApi({
    url: `/api/guests/${id}/verify`,
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
  }, [ setGuest ])

  const verifyGuest = (body) => {
    setRequestPayload(JSON.stringify(body))
    request()
  }

  return { verifyGuest, isLoading }
}

const useUnverifyGuest = (id, setGuest) => {
  const { request, isLoading, setRequestPayload } = useJsonApi({
    url: `/api/guests/${id}/verify`,
    method: 'DELETE',
    credentials: 'include',
    mode: 'cors',
  }, [ setGuest ])

  const unverifyGuest = (body) => {
    setRequestPayload(JSON.stringify(body))
    request()
  }

  return { unverifyGuest, isLoading }
}

const useCheckinGuest = (id, finalizers = []) => {
  const { request, isLoading } = useJsonApi({
    url: `/api/guests/${id}/checkin`,
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
  }, finalizers)

  return { checkinGuest: request, isLoading }
}

export {
  useReadGuests,
  useCreateGuest,
  useReadGuest,
  useUpdateGuest,
  useSendGuestEmail,
  useVerifyGuest,
  useUnverifyGuest,
  useCheckinGuest,
}
