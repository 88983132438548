import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Typography,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Icon,
} from '@mui/material'
import MaterialTable from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters'

import { useDebouncedCallback } from 'use-debounce'
import { useReadGuests, useUpdateGuest, useCreateGuest, useSendGuestEmail } from '../hooks/useDataApi'
import { classes } from '../hooks/useStyles'

import { theme } from '../index'

const Admin = () => {
  const [ columns ] = useState([
    { title: 'Email', field: 'email', width: 400 },
    // { title: 'Rag. Soc.', field: 'role' },
    { title: 'Saluto', field: 'salutation' },
    { title: 'Titolo', field: 'title' },
    { title: 'Nome', field: 'firstName' },
    { title: 'Cognome', field: 'lastName' },
    { title: 'Posto', field: 'seat' },
    { title: 'Tipo', field: 'type', lookup: { main: 'Main', limited: 'Limited', vip: 'VIP' } },
    { title: 'Categoria', field: 'category' },
    { title: 'Note', field: 'note' },
    { title: 'Invito', field: 'invitation', type: 'boolean' },
    { title: 'Conferma', field: 'confirmation', type: 'boolean'},
    { title: '#', field: 'confirmationNumber', type: 'numeric', editable: 'never' },
    { title: 'Fuori', field: 'overQuota', type: 'boolean' },
    { title: 'Bounce', field: 'bounce', type: 'boolean'},
    { title: 'Confermato alle', field: 'confirmedAt' },
    { title: 'Checkin', field: 'checkin', type: 'boolean'},
    { title: 'Non viene', field: 'noShow', type: 'boolean'},
  ])

  const [ isSendingEmails, setIsSendingEmails ] = useState(false)
  const [ selectedEmails, setSelectedEmails ] = useState([])
  const [ emailLogs, setEmailLogs ] = useState([])

  const location = useLocation()
  const { guests, setGuests } = useReadGuests()
  const { createGuest } = useCreateGuest()
  const { updateGuest } = useUpdateGuest()
  const { sendGuestEmail } = useSendGuestEmail()

  const [ values, setValues ] = useState({
    type: '',
    list: '',
    emails: '',
  })
  const handleChange = ({ target: { name, value } }) => {
    setValues(oldValues => ({
      ...oldValues,
      [name]: value,
    }))
  }
  const [ debouncedValues, setDebouncedValues ] = useState({
    type: '',
    list: '',
    emails: '',
  })
  const debouncedHandleChange = ({ target: { name, value } }) => {
    setDebouncedValues(oldValues => ({
      ...oldValues,
      [name]: value,
    }))
  }
  const [ debouncedHandleCallback ] = useDebouncedCallback(
    (e) => debouncedHandleChange(e),
    750
  )

  useEffect(() => {
    const selectedGuests = guests.filter(({ email, invitation, confirmation, bounce, overQuota, type }) => {
      if (bounce) {
        return 0
      }
      switch (values.list) {
        case 'not.invited':
          return !invitation
        case 'yes.invited':
          return invitation
        case 'not.confirmed':
          return !confirmation
        case 'main.not.confirmed':
          return !confirmation && type === 'main'
        case 'limited.not.confirmed':
          return !confirmation && type === 'limited'
        case 'yes.confirmed':
          return confirmation
        case 'main.yes.confirmed':
          return confirmation && type === 'main'
        case 'limited.yes.confirmed':
          return confirmation && type === 'limited'
        case 'quota.in':
          return !overQuota
        case 'quota.out':
          return overQuota
        case 'type.main':
          return type === 'main'
        case 'type.limited':
          return type === 'limited'
        case 'type.vip':
          return type === 'vip'
        case 'emails':
          return (
            debouncedValues.emails.includes(email + ' ') ||
            debouncedValues.emails.includes(email + ',') ||
            debouncedValues.emails.includes(email + '\n') ||
            debouncedValues.emails.includes(email + '\r') ||
            debouncedValues.emails.includes(email + '\t') ||
            debouncedValues.emails.endsWith(email)
          )
        default:
          return 0
      }
    })
    setSelectedEmails(selectedGuests)
  }, [ values.type, values.list, debouncedValues.emails, guests ])

  const sendEmails = useCallback(async () => {
    setIsSendingEmails(true)
    setEmailLogs([])
    for (const guest of selectedEmails) {
      await sendGuestEmail(guest._id, values.type, [(response) => {
        if (response.status >= 400) {
          const { message, status } = response
          emailLogs.push({ message, status, email: guest.email })
          setEmailLogs([...emailLogs])
          return
        }
        setGuests((oldGuests) => {
          const data = [...oldGuests]
          data[data.indexOf(guest)] = response

          return data
        })
      }])
    }
    setIsSendingEmails(false)
  }, [emailLogs, selectedEmails, sendGuestEmail, setGuests, values.type])

  const page = location.pathname === '/admin'
    ? 'admin'
    : 'user'

  if (!guests.length) {
    return null
  }

  const emailComponent = (<Paper className={[classes.wrap, classes.spaceBottom].join(' ')}>
    <Typography variant="h6">Invio email</Typography>
    <div style={{ position: 'relative' }}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="type">Email da inviare</InputLabel>
        <Select
          value={values.type}
          onChange={handleChange}
          inputProps={{
            name: 'type',
            id: 'type',
          }}
        >
          <MenuItem value={'invitation'}>Invito</MenuItem>
          <MenuItem value={'qr'}>QR</MenuItem>
          <MenuItem value={'qr-reminder'}>QR Reminder</MenuItem>
          <MenuItem value={'pending'}>Attesa di conferma</MenuItem>
          <MenuItem value={'repechage'}>Ripescaggio</MenuItem>
          <MenuItem value={'reminder'}>Reminder</MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="list">Utenti a cui inviare</InputLabel>
        <Select
          value={values.list}
          onChange={handleChange}
          inputProps={{
            name: 'list',
            id: 'list',
          }}
        >
          <MenuItem value={'not.invited'}>Da invitare</MenuItem>
          <MenuItem value={'yes.invited'}>Già invitati</MenuItem>
          <MenuItem value={'not.confirmed'}>Da confermare</MenuItem>
          <MenuItem value={'main.not.confirmed'}>Main: Da confermare</MenuItem>
          <MenuItem value={'limited.not.confirmed'}>Limited: Da confermare</MenuItem>
          <MenuItem value={'yes.confirmed'}>Già confermati</MenuItem>
          <MenuItem value={'main.yes.confirmed'}>Main: Già confermati</MenuItem>
          <MenuItem value={'limited.yes.confirmed'}>Limited: Già confermati</MenuItem>
          <MenuItem value={'quota.in'}>In quota</MenuItem>
          <MenuItem value={'quota.out'}>Over quota</MenuItem>
          <MenuItem value={'type.main'}>Tipo: Main</MenuItem>
          <MenuItem value={'type.limited'}>Tipo: Limited</MenuItem>
          <MenuItem value={'type.vip'}>Tipo: VIP</MenuItem>
          <MenuItem value={'emails'}>Lista di email</MenuItem>
        </Select>
      </FormControl>

      <Button
        style={{ position: 'absolute', bottom: 0, right: 0 }}
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<Icon>send</Icon>}
        disabled={
          !selectedEmails.length ||
          isSendingEmails
        }
        onClick={sendEmails}
      >
        {
          isSendingEmails
          ? `Inviando ${selectedEmails.length} email`
          : `Invia ${selectedEmails.length} email`
        }
      </Button>
    </div>
    <div>
      {
        values.list === 'emails' &&
        <TextField
          name="emails"
          label="Inserisci le mail a cui inviare"
          multiline
          rows="2"
          maxRows="4"
          value={values.emails}
          onChange={(e) => {
            const { target: { name, value } } = e
            handleChange({ target: { name, value } })
            debouncedHandleCallback({ target: { name, value } })
          }}
          className={classes.textField}
          margin="normal"
          fullWidth
        />
      }
    </div>
    {
      !!emailLogs.length && (
        <pre>{ ['ERRORI'].concat(emailLogs
          .map(({ message, status, email }) => `${email} (${status}) - ${message}`)
        ).join('\n')
        }</pre>
      )
    }
  </Paper>)

  const editable = {
    onRowAdd: newData => new Promise(resolve => {
      createGuest(newData, [(response) => {
        const data = [...guests]
        data.push(response)
        setGuests(data)
        resolve()
      }])
    }),
    onRowUpdate: (newData, oldData) => new Promise(resolve => {
      updateGuest(oldData._id, newData, [(response) => {
        const data = [...guests]
        data[data.indexOf(oldData)] = response
        setGuests(data)
        resolve()
      }])
    }),
  }

  return (
    <>
      {
        page === 'admin'
        ? emailComponent
        : null
      }
      <div className={classes.spaceBottom}>
        <MaterialTable
          title="Lista ospiti"
          columns={columns}
          data={guests}
          options={{
            exportAllData: true,
            exportMenu: [{
              label: 'Export PDF',
              exportFunc: (cols, datas) => ExportPdf(cols, datas, 'guests')
            }, {
              label: 'Export CSV',
              exportFunc: (cols, datas) => ExportCsv(cols, datas, 'guests')
            }],
            grouping: true,
            pageSize: 10,
            pageSizeOptions: [10, 25],
            // selection: true,
          }}
          editable={
            page === 'admin'
            ? editable
            : null
          }
          theme={theme}
        />
      </div>
    </>
  )
}

export default Admin
