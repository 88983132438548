import { styled } from '@mui/material/styles';

const PREFIX = 'Root';

export const classes = {
  root: `${PREFIX}-root`,
  logoBackground: `${PREFIX}-logoBackground`,
  logoHolder: `${PREFIX}-logoHolder`,
  wrap: `${PREFIX}-wrap`,
  header: `${PREFIX}-header`,
  spaceBottom: `${PREFIX}-spaceBottom`,
  spaceTop: `${PREFIX}-spaceTop`,
  padBottom: `${PREFIX}-padBottom`,
  padTop: `${PREFIX}-padTop`,
  iconSmall: `${PREFIX}-iconSmall`,
  formControl: `${PREFIX}-formControl`,
  textField: `${PREFIX}-textField`,
};

export const StyledRoot = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.logoBackground}`]: {
    backgroundColor: '#1C355D',
  },
  [`& .${classes.logoHolder}`]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100vw',
    maxHeight: 500,
    backgroundImage: 'url(/Header_sito_4265x1000_gradienti.png)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.wrap}`]: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  [`& .${classes.header}`]: {
    textAlign: 'center',
  },
  [`& .${classes.spaceBottom}`]: {
    marginBottom: theme.spacing(2.5),
  },
  [`& .${classes.spaceTop}`]: {
    marginTop: theme.spacing(2.5),
  },
  [`& .${classes.padBottom}`]: {
    paddingBottom: theme.spacing(2.5),
  },
  [`& .${classes.padTop}`]: {
    paddingTop: theme.spacing(2.5),
  },
  [`& .${classes.iconSmall}`]: {
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  [`& .${classes.formControl}`]: {
    marginTop: `${theme.spacing(1)} !important`,
    marginRight: `${theme.spacing(2)} !important`,
    minWidth: '200px !important',
  },
  [`& .${classes.textField}`]: {
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // width: 200,
  },
}))
