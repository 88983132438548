import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container,
  Typography,
  Button,
  Paper,
  Grid,
  TextField,
} from '@mui/material'

import './Home.css'
import Footer from './Footer'

import { useReadGuest, useUnverifyGuest, useVerifyGuest } from '../hooks/useDataApi'
import { classes } from '../hooks/useStyles'

const GuestConfirmation = () => {
  const { token } = useParams()
  const { guest, setGuest } = useReadGuest(token)
  const { verifyGuest, isLoadingVerify } = useVerifyGuest(token, setGuest)
  const { unverifyGuest, isLoadingUnverify } = useUnverifyGuest(token, setGuest)

  const isLoading = isLoadingVerify || isLoadingUnverify

  const [ values, setValues ] = useState({
    firstName: '',
    lastName: '',
  })
  const handleChange = ({ target: { name, value } }) => {
    setValues(oldValues => ({
      ...oldValues,
      [name]: value,
    }))
  }

  useEffect(() => {
    setValues({ ...guest })
  }, [guest])

  if (!guest.email || guest.type === 'main' || guest.type === 'vip') {
    return null
  }

  return (
    <Grid item xs={12}>
      <Paper className={[ classes.wrap, classes.header ].join(' ')}>
        <Typography variant="h6" gutterBottom>
          {guest.firstName && (
            <>
              {guest.salutation || 'Gentile'} {guest.title} {guest.firstName} {guest.lastName}<br />
            </>
          )}
          {guest.firstName ? 's' : 'S'}e desidera partecipare alla Cerimonia di inaugurazione dell'Anno Accademico 2023-2024, proceda con la conferma.<br/><br />
          <b>Mercoledì 29 novembre 2023</b><br />
          <b>ore 11.00</b><br />
          Aula Magna dell’Università degli studi di Bergamo<br />
          Piazzale S. Agostino 2, Bergamo<br />
        </Typography>

        {!guest.confirmation && guest.type === 'limited' && (
          <>
            <Typography variant="body1" gutterBottom>
              Inserisca i suoi dati
            </Typography>
            <TextField
              name="firstName"
              label="Nome"
              value={values.firstName}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <br />
            <TextField
              name="lastName"
              label="Cognome"
              value={values.lastName}
              onChange={handleChange}
              sx={{ mb: 1 }}
            />
            <br />
          </>
        )}

        <Button
          variant="contained"
          className={classes.button}
          style={{
            backgroundColor: guest.confirmation
              ? '#2DDD2A'
              : isLoading || !values.firstName || !values.lastName
                ? 'gray'
                : '#DD2929',
            color: 'rgba(255, 255, 255, 0.9)',
          }}
          size="large"
          onClick={() => {
            if (guest.confirmation) {
              unverifyGuest(values)
            } else {
              verifyGuest(values)
            }
          }}
          disabled={isLoading || !values.firstName || !values.lastName}
        >
          {
            guest.confirmation
            ? 'La partecipazione è confermata, clicchi per annullarla'
            : isLoading
              ? 'Conferma in corso'
              : 'Confermi partecipazione'
          }
        </Button>
      </Paper>
    </Grid>
  )
}

const Header = () => {
  return (
    <Paper className={[ classes.header, classes.spaceBottom ].join(' ')}>
      <div className={classes.logoBackground}>
        <div className={classes.logoHolder}></div>
      </div>
    </Paper>
  )
}

const Infos = () => {
  const { token } = useParams()

  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      {token !== 'public' && <GuestConfirmation />}
      <Grid item xs={12} lg={6}>
        <Paper className={[ classes.wrap, classes.spaceBottom ].join(' ')}>
          <Typography variant="h5" gutterBottom>
            Programma della Cerimonia
          </Typography>
          <Typography variant="body1">
            <b>ore 10.30</b><br />
            Accoglienza invitati<br />
            <br />
            <b>ore 11.00</b><br />
            Corteo accademico<br />
            <br />
            <i>Inno d’Italia e Inno dell’Unione Europea</i><br />
            <br />
            Video messaggio Presidente del Parlamento Europeo<br />
            Roberta Metsola<br />
            <br />
            Saluto delle Autorità<br />
            <br />
            Intervento del Rappresentante<br />
            del Personale Tecnico Amministrativo e Bibliotecario<br />
            <br />
            Intervento della Rappresentante<br />
            delle Studentesse e degli Studenti<br />
            <br />
            Prolusione del Magnifico Rettore<br />
            <b>Sergio Cavalieri</b><br />
            <br />
            <i>Lectio magistralis</i><br />
            <b>Prof. Michel Lussault</b><br />
            Professore ordinario di geografia presso<br/>
            l’ENS-Ecole Normale Supérieure de Lyon<br />
            <br />
            <i>Gaudeamus Igitur</i>
          </Typography>
        </Paper>
        <Paper className={classes.wrap}>
          <Typography variant="h6" gutterBottom>
            InfoLine
          </Typography>
          <Typography variant="body2">
            <a href="mailto:inaugurazione.aa@unibg.it">inaugurazione.aa@unibg.it</a> - <a href="tel:+390352052240">035 2052240</a>; <a href="tel:+390352052218">035 2052218</a><br />
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.wrap}>
          <Typography variant="h5" gutterBottom>
            Come raggiungere l'evento
          </Typography>
          <a
            href="https://www.google.it/maps/place/Ex-Monastero+di+Sant'Agostino+-+Aula+Magna+-+Università+degli+Studi+di+Bergamo/@45.7049965,9.6665281,17z/data=!3m1!4b1!4m6!3m5!1s0x4781513da26b110d:0xdcf9403893152407!8m2!3d45.7049929!4d9.671399!16s%2Fg%2F11bc8c4qp1?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/map_santagostino.png" alt="Map" style={{ width: '100%' }} />
          </a>
        </Paper>
      </Grid>
    </Grid>
  )
}

const Home = () => {
  return (
    <div>
      <Header />
      <Container maxWidth="lg">
        <Infos />
      </Container>
      <Footer />
    </div>
  )
}

export default Home
