import { Button, Container, TextField, Typography } from "@mui/material"
import { useCallback, useState } from "react"

const baseUrl = process.env.REACT_APP_API_URL

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [isError, setIsError] = useState(false)

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setInProgress(true)
    setIsError(false)

    try {
      const response = await fetch(`${baseUrl}/api/login`, {
        body: JSON.stringify({ username, password }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'post',
      })
      if (response.status !== 200) {
        setIsError(true)
      } else {
        if (username === 'user') {
          window.location.pathname = '/verify/dashboard'
        } else {
          window.location.pathname = '/admin'
        }
      }
    } catch (e) {
      console.error(e)
    }
    setInProgress(false)
  }, [username, password])

  return (
    <Container maxWidth="sm" >
      <form onSubmit={onSubmit}>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => {
            setIsError(false)
            setUsername(e.target.value)
          }}
          fullWidth
          style={{
            marginTop: '12px'
          }}
          required
        />
        <TextField
          label="Password"
          value={password}
          onChange={(e) => {
            setIsError(false)
            setPassword(e.target.value)
          }}
          type="password"
          fullWidth
          style={{
            marginTop: '12px'
          }}
          required
        />
        <Button
          variant="contained"
          fullWidth
          disabled={inProgress}
          style={{
            marginTop: '24px'
          }}
          type="submit"
        >
          Login
        </Button>
        {isError && (
          <Typography variant="subtitle1" color="error" align="center" style={{ marginTop: '24px'}}>
            Errore!
          </Typography>
        )}
      </form>
    </Container>
  )
}

export default Login
