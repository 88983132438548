import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  colors,
} from '@mui/material'

import { useReadGuest, useCheckinGuest } from '../hooks/useDataApi'

import { classes } from '../hooks/useStyles'

const { grey, red, blue, yellow } = colors

const Verify = () => {
  const { token } = useParams()
  const { guest, setGuest } = useReadGuest(token, () => checkinGuest())
  const { checkinGuest, isLoading } = useCheckinGuest(token, [ setGuest ])

  if (!guest || !guest._id) {
    return null
  }

  // const d = new Date(guest.birthDate)
  // const day = d.getDate()
  // const month = d.getMonth() + 1
  // const year = d.getFullYear()
  // const birthDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`

  const checkinAt = new Date(guest.checkinAt || 0)
  const hours = checkinAt.getHours()
  const minutes = checkinAt.getMinutes()
  const checkinTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`

  const seat = guest.seat.toLowerCase()
  const seatColor = seat.includes('rossa')
    ? red[400]
    : seat.includes('blu')
    ? blue[400]
    : seat.includes('giallo')
    ? yellow[600]
    : grey[600]

  return (
    <Container>
      <Paper className={[classes.wrap, classes.spaceBottom].join(' ')}>
        <Typography variant="h6" gutterBottom>
          Check-in
        </Typography>
        <Button
          variant="contained"
          className={[classes.button, classes.spaceBottom].join(' ')}
          style={{
            backgroundColor: isLoading ? grey[400] : guest.checkin ? '#2DDD2A' : '#DD2929',
            color: 'rgba(255, 255, 255, 0.9)',
          }}
          size="large"
          disabled
        >
          {
            isLoading
            ? 'Attendere ...'
            : guest.checkin
            ? `Check-in effettuato @ ${checkinTime}`
            : 'Check-in non effettuato'
          }
        </Button>
        <Table><TableBody>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell><i>{ guest.title }</i>&nbsp;<b>{ guest.firstName } { guest.lastName }</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Rag. Soc.</TableCell>
            <TableCell><b>{ guest.role }</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell><b>{ guest.email }</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Posto</TableCell>
            <TableCell><b style={{ color: seatColor }}>{ guest.seat }</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Conferma</TableCell>
            <TableCell><b>{ guest.confirmation ? 'Confermato' : 'Non confermato' }</b></TableCell>
          </TableRow>
        </TableBody></Table>
      </Paper>
    </Container>
  )
}

export default Verify
