import 'whatwg-fetch'

import React, { Fragment } from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'

import './index.css'
import Home from './components/Home'
import Admin from './components/Admin'
import Verify from './components/Verify'

import * as serviceWorker from './serviceWorker'
import { StyledRoot } from './hooks/useStyles';
import Login from './components/Login';

export const theme = createTheme({
  typography: {
    fontFamily: '"Rubik", sans-serif',
  },
  palette: {
    text: {
      primary: '#1B355D',
    },
  },
})

const App = () => {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <StyledRoot sx={{ maxWidth: 2560, marginLeft: 'auto', marginRight: 'auto' }}>
            <Router>
              <Routes>
                <Route exact path="/" element={<Navigate replace to="/event/public" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/event/:token" element={<Home />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/verify/dashboard" exact element={<Admin />} />
                <Route path="/verify/:token" element={<Verify />} />
              </Routes>
            </Router>
          </StyledRoot>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

ReactDOM.createRoot(document.getElementById('root'))
  .render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
