import { Container, Typography, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';

const PREFIX = 'Footer';

const classes = {
  footer: `${PREFIX}-footer`,
  logo: `${PREFIX}-logo`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.footer}`]: {
    marginTop: theme.spacing(8),
    backgroundColor: '#1B355D',
    color: 'white',
    padding: theme.spacing(2)
  },

  [`& .${classes.logo}`]: {
    maxWidth: 150,
  }
}));

const Footer = () => {
  return (
    <Root className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={2} md={3}>
            <img src="/logo-white-square.png" alt="Logo" className={classes.logo} />
          </Grid>
          <Grid item md={3}>
            <Typography variant="body2">
              <b>Università degli studi di Bergamo</b><br />
              via Salvecchio 19<br />
              24129 Bergamo<br />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
}

export default Footer
